import { template as template_1f3ee9f9290b4c5c955db8b21d0bf91d } from "@ember/template-compiler";
const FKLabel = template_1f3ee9f9290b4c5c955db8b21d0bf91d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
