import { template as template_855eab2ebb6a4ffc93634aec9e16d526 } from "@ember/template-compiler";
const WelcomeHeader = template_855eab2ebb6a4ffc93634aec9e16d526(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
