import { template as template_a5fdc2b18c48485ca999c229a35d9f1a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a5fdc2b18c48485ca999c229a35d9f1a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
